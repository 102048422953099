import { useMutation } from '@sixfold/app-data-framework';
import { useToast } from '@sixfold/common-ui';
import { Localized, useLocalizations } from '@sixfold/localization-component';

import { useViewerCompany } from './hooks';
import {
  RemoveUserFromCompanyMutation,
  RemoveUserFromCompanyMutationVariables,
  AddUserToCompanyMutation,
  AddUserToCompanyMutationVariables,
  UserRoleInCompany,
  ChangeUserRoleInCompanyMutation,
  ChangeUserRoleInCompanyMutationVariables,
  UpdateUserInCompanyConfigurationMutationVariables,
  UpdateUserInCompanyConfigurationMutation,
  UserInCompanyConfigurationInput,
} from '../../lib/graphql';
import { UserInCompany } from '../entities';
import {
  companyUsers,
  removeUserFromCompany,
  addUserToCompany,
  changeUserRoleInCompany,
  updateUserInCompanyConfiguration,
  viewerCompany,
} from '../graphql';
import { getUserName } from '../lib/entities';

interface MutationOptions {
  disableNotification?: boolean;
}

export interface MutationFunctions {
  removeUser: (user: UserInCompany) => Promise<void>;
  makeAdmin: (user: UserInCompany) => Promise<void>;
  addUser: (
    user: { firstName: string; lastName: string; email: string },
    options?: MutationOptions,
  ) => Promise<UserInCompany | void>;
  updateUserConfiguration: (configuration: UserInCompanyConfigurationInput) => Promise<void>;
}

export function useCompanyUsersMutations(): MutationFunctions {
  const {
    company: { company_id },
  } = useViewerCompany();
  const [addUserMutation] = useMutation<AddUserToCompanyMutation, AddUserToCompanyMutationVariables>(addUserToCompany);
  const [removeUserMutation] = useMutation<RemoveUserFromCompanyMutation, RemoveUserFromCompanyMutationVariables>(
    removeUserFromCompany,
  );
  const [changeUserRoleMutation] = useMutation<
    ChangeUserRoleInCompanyMutation,
    ChangeUserRoleInCompanyMutationVariables
  >(changeUserRoleInCompany);
  const [updateUserConfigurationMutation] = useMutation<
    UpdateUserInCompanyConfigurationMutation,
    UpdateUserInCompanyConfigurationMutationVariables
  >(updateUserInCompanyConfiguration);

  const notify = useToast();

  const dictionary = useLocalizations({
    'company.users.notification.removedUserFromCompany.undo.button.title': 'Undo',
    'company.users.notification.removedUserFromCompany.error': 'Unexpected error occurred, please try again later',
  });

  const addUser: MutationFunctions['addUser'] = async (user, options) => {
    try {
      const result = await addUserMutation({
        variables: {
          input: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            companyId: company_id,
          },
        },
        // Refetch the main query, wait until the refetch has completed
        refetchQueries: [{ query: companyUsers, variables: { company_id } }],
        awaitRefetchQueries: true,
      });

      if (result?.data?.addUserToCompany) {
        if (options?.disableNotification !== true) {
          const { profile } = result.data.addUserToCompany;

          notify.success(
            <Localized id="company.users.notification.addedUserToCompany">
              <em>{{ name: getUserName(profile) }}</em>
              <span> has been added, invite email sent to </span>
              <em>{{ email: profile.email }}</em>
            </Localized>,
          );
        }

        return result.data.addUserToCompany;
      }

      throw new Error('Unknown error');
    } catch (e) {
      if (e.message.includes('ALREADY_IN_COMPANY')) {
        notify.danger(
          <Localized id="company.users.notification.addedUserToCompany.error.alreadyInCompany">
            This user is already in the company
          </Localized>,
        );
        return;
      }

      notify.danger(
        <Localized id="company.users.notification.addedUserToCompany.error">
          Unexpected error occurred, please try again later
        </Localized>,
      );
      return;
    }
  };

  const removeUser: MutationFunctions['removeUser'] = async (user) => {
    try {
      const result = await removeUserMutation({
        variables: {
          input: {
            userId: user.profile.user_id,
            companyId: company_id,
          },
        },
        // Refetch the main query, wait until the refetch has completed
        refetchQueries: [{ query: companyUsers, variables: { company_id } }],
        awaitRefetchQueries: true,
      });

      if (result && result.data && result.data.removeUserFromCompany) {
        const { first_name, last_name, email } = user.profile;
        const name = getUserName(user.profile);

        notify.success(
          <Localized id="company.users.notification.removedUserFromCompany">
            <em>{{ name }}</em> has been removed
          </Localized>,
          {
            action:
              email && first_name && last_name
                ? {
                    title: dictionary['company.users.notification.removedUserFromCompany.undo.button.title'],
                    onClick: async () => {
                      await addUser({
                        firstName: first_name,
                        lastName: last_name,
                        email,
                      });

                      return true;
                    },
                  }
                : undefined,
          },
        );
      } else {
        throw new Error('Unknown error');
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      notify.danger(
        <Localized id="company.users.notification.removedUserFromCompany.error">
          Unexpected error occurred, please try again later
        </Localized>,
      );
    }
  };

  const changeUserRole = async (user: UserInCompany, role: UserRoleInCompany) => {
    return await changeUserRoleMutation({
      variables: {
        input: {
          role,
          companyId: company_id,
          userId: user.profile.user_id,
        },
      },
      // Refetch the main query, wait until the refetch has completed
      refetchQueries: [{ query: companyUsers, variables: { company_id } }],
      awaitRefetchQueries: true,
    });
  };

  const makeAdmin: MutationFunctions['makeAdmin'] = async (user) => {
    try {
      const result = await changeUserRole(user, UserRoleInCompany.ADMIN);

      if (result && result.data && result.data.changeUserRoleInCompany) {
        const name = getUserName(user.profile);

        notify.success(
          <Localized id="company.users.notification.userChangedToCompanyAdmin">
            <em>{{ name }}</em> promoted to admin
          </Localized>,
          {
            action: {
              title: dictionary['company.users.notification.removedUserFromCompany.undo.button.title'],
              onClick: async () => {
                await changeUserRole(user, UserRoleInCompany.REGULAR_USER);
                notify.success(
                  <Localized id="company.users.notification.userChangedToRegularUser">
                    <em>{{ name }}</em> changed to regular user
                  </Localized>,
                );

                return true;
              },
            },
          },
        );
      } else {
        throw new Error('Unknown error');
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      notify.danger(
        <Localized id="company.users.notification.changeUserToCompanyAdmin.error">
          Unexpected error occurred, please try again later
        </Localized>,
      );
    }
  };

  const updateUserConfiguration: MutationFunctions['updateUserConfiguration'] = async (configuration) => {
    await updateUserConfigurationMutation({
      variables: {
        input: {
          companyId: company_id,
          configuration,
        },
      },
      refetchQueries: [{ query: viewerCompany, variables: { company_id } }],
      awaitRefetchQueries: true,
    });
  };

  return {
    addUser,
    removeUser,
    makeAdmin,
    updateUserConfiguration,
  };
}
