import 'core-js/actual';

import { ApolloProvider, useConfiguredApolloClient } from '@sixfold/app-data-framework';
import {
  ErrorHandlerProvider,
  useErrorHandler,
  useConstant,
  useBooleanLocalStorage,
  lazyWithRetry,
} from '@sixfold/app-utils';
import {
  NotificationBoundary,
  SpinnerOverlay,
  LocalizationProvider,
  LocalizationDictionary,
  ToastProvider,
} from '@sixfold/common-ui';
import { initialize as initializeI18n, i18n, useLanguage, useLocalizations } from '@sixfold/localization-component';
import { notNil } from '@sixfold/typed-primitives';
import {
  FunctionComponent,
  PropsWithChildren,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { App } from './app';
import { AnalyticsProvider } from './components/analytics';
import { EmbedDataProvider, EmbedDataContext } from './components/embed_data_context';
import { ErrorBoundary } from './components/error_boundary';
import { TrackingConsentPopup } from './components/tracking_consent_popup';
import { UpdateNotifier } from './components/update_notifier';
import { possibleTypes, typePolicies } from './lib/apollo_client/utils';
import { getEmbedConfig, getEmbedData } from './lib/data';
import { getIsConsentGiven } from './lib/util/cookie_consent';
import { RouterProvider } from './router_provider';
import { sentryOptions } from './sentry';
import { ThemeWrapper } from './theme_wrapper';
import { useUserProfile } from './user';

// import after ThemeWrapper brings in common-ui reset and other assets
import './styles/main.module.css';

const ShareRoute = lazyWithRetry(() => import('./routes/share').then(({ ShareRoute }) => ({ default: ShareRoute })));

const embedConfig = getEmbedConfig();
const embedData = getEmbedData();

initializeI18n({
  detectBrowserLanguage: {
    cookieDomain: embedConfig !== null ? embedConfig.cookie_domain : undefined,
  },
  logMissingKeys: false,
  useHttpBackend: {
    loadPath: (lng, ns) => `/locales/${lng}/${ns}?key=${embedData.translations_cache_keys?.[lng[0]] ?? ''}`,
  },
});

calculateScrollbarWidth();

const UserLocaleSync: FunctionComponent<PropsWithChildren<unknown>> = () => {
  const user = useUserProfile();
  const language = useLanguage();

  useEffect(() => {
    if (notNil(user) && notNil(user?.locale) && user?.locale !== language) {
      i18n.changeLanguage(user.locale).catch((e) => console.error('Failed to change user locale', e));
    }
  }, [language, user]);

  // We don't render anything
  return null;
};

const AppWrapper: FunctionComponent<
  PropsWithChildren<{
    isShareLink?: boolean;
    logOut: () => void;
  }>
> = ({ logOut, isShareLink }) => {
  const [isAnalyticsConsented, setIsAnalyticsConsented] = useState<boolean>(getIsConsentGiven());

  const commonUIlocalizations = useLocalizations({
    'common.ui.clear.title': 'Clear',
    'common.ui.close.title': 'Close',
    'common.ui.remove.title': 'Remove',
    'common.ui.sort.ascending.title': 'Sort ascending',
    'common.ui.sort.descending.title': 'Sort descending',
    'common.ui.table.selectRow': 'Select',
    'common.ui.table.selectAllRows': 'Select all',
    'common.ui.tree.expandItem': 'Expand',
    'common.ui.toast.progressLine': 'Progress line',
    'common.ui.treeSelect.search': 'Search',
    'component.filter.multiselect.noResults.label': 'We could not find any results for this search',
    'common.ui.treeSelect.search.checkAndTryAgain': 'Please check your search keyword and try again.',
    'common.ui.multiSelect.create': 'Create',
  });
  const localizationProviderDictionary: LocalizationDictionary = useMemo(
    () => ({
      clear: commonUIlocalizations['common.ui.clear.title'],
      close: commonUIlocalizations['common.ui.close.title'],
      remove: commonUIlocalizations['common.ui.remove.title'],
      sortAscending: commonUIlocalizations['common.ui.sort.ascending.title'],
      sortDescending: commonUIlocalizations['common.ui.sort.descending.title'],
      'table.selectRow': commonUIlocalizations['common.ui.table.selectRow'],
      'table.selectAllRows': commonUIlocalizations['common.ui.table.selectAllRows'],
      'tree.expandItem': commonUIlocalizations['common.ui.tree.expandItem'],
      search: commonUIlocalizations['common.ui.treeSelect.search'],
      progressLine: commonUIlocalizations['common.ui.toast.progressLine'],
      'search.noResults': commonUIlocalizations['component.filter.multiselect.noResults.label'],
      'search.checkAndTryAgain': commonUIlocalizations['common.ui.treeSelect.search.checkAndTryAgain'],
      'multiSelect.create': commonUIlocalizations['common.ui.multiSelect.create'],
    }),
    [commonUIlocalizations],
  );
  const notificationClassname = useConstant(() => ({
    wrapper: 'notifications',
    container: 'container',
    content: 'content',
  }));

  return (
    <Router>
      <RouterProvider>
        <LocalizationProvider dictionary={localizationProviderDictionary}>
          <NotificationBoundary className={notificationClassname}>
            <ToastProvider>
              <AnalyticsProvider consented={isAnalyticsConsented}>
                <RecoilRoot>
                  <Suspense fallback={<SpinnerOverlay />}>
                    {isShareLink ? (
                      <>
                        <UpdateNotifier />
                        <ShareRoute />
                      </>
                    ) : (
                      <>
                        <UserLocaleSync />
                        <App logOut={logOut} />
                      </>
                    )}
                    <TrackingConsentPopup onUserConsentChanged={setIsAnalyticsConsented} />
                  </Suspense>
                </RecoilRoot>
              </AnalyticsProvider>
            </ToastProvider>
          </NotificationBoundary>
        </LocalizationProvider>
      </RouterProvider>
    </Router>
  );
};

const SessionWrapper: FunctionComponent<PropsWithChildren<unknown>> = () => {
  const { embedConfig, embedData } = useContext(EmbedDataContext);
  const [enablePersistedQueries] = useBooleanLocalStorage('enable_persisted_queries', true);
  const errorHandler = useErrorHandler();
  const authToken = embedData.auth_token;

  const [logOutRedirect, setLogOutRedirect] = useState<string | undefined>(undefined);

  const logOut = useCallback(() => {
    if (embedConfig === null) {
      throw new Error('No config: could not read login_web_url to redirect');
    }

    setLogOutRedirect(`${embedConfig.login_web_url}/logout`);
  }, [embedConfig]);

  const getAuthorizationToken = useCallback(() => authToken, [authToken]);
  const onReportError = useCallback(
    (error: Error, extra?: Record<string, unknown>) => errorHandler?.captureError(new Error(error.message), { extra }),
    [errorHandler],
  );
  const onAuthenticationError = useCallback(() => {
    if (embedConfig === null) {
      throw new Error('No config: could not read login_web_url to redirect');
    }

    setLogOutRedirect(`${embedConfig.login_web_url}?next=${encodeURIComponent(window.location.href)}`);
  }, [setLogOutRedirect, embedConfig]);

  const apolloClient = useConfiguredApolloClient({
    baseURL: embedConfig?.base_assets_url,
    possibleTypes,
    typePolicies,
    getAuthorizationToken,
    onReportError,
    onAuthenticationError,
    enablePersistedQueries,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
    },
  });

  if (logOutRedirect !== undefined) {
    window.location.href = logOutRedirect;
    return <SpinnerOverlay />;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <Suspense fallback={<SpinnerOverlay />}>
        <ErrorBoundary compact={false}>
          <AppWrapper isShareLink={embedData.is_share_link} logOut={logOut} />
        </ErrorBoundary>
      </Suspense>
    </ApolloProvider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <EmbedDataProvider value={{ embedConfig, embedData }}>
    <ErrorHandlerProvider sentryOptions={sentryOptions}>
      <ErrorBoundary compact={false}>
        <ThemeWrapper>
          <ToastProvider>
            <Suspense fallback={<SpinnerOverlay />}>
              <SessionWrapper />
            </Suspense>
          </ToastProvider>
        </ThemeWrapper>
      </ErrorBoundary>
    </ErrorHandlerProvider>
  </EmbedDataProvider>,
);

function calculateScrollbarWidth() {
  const scrollDiv = document.createElement('div');
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.visibility = 'hidden';
  scrollDiv.style.overflow = 'scroll';
  scrollDiv.style.width = '100px';
  scrollDiv.style.height = '100px';
  scrollDiv.style.top = '-9999px';

  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.documentElement.style.setProperty('--calculated-scrollbar-width', `${scrollbarWidth}px`);

  document.body.removeChild(scrollDiv);
}

const removeLoadingSpinner = () => {
  const loadingElement = document.getElementById('loading');
  if (loadingElement && loadingElement.parentNode) {
    loadingElement.parentNode.removeChild(loadingElement);
  }
};

removeLoadingSpinner();
