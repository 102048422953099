export enum IntegrationRouteParameters {
  NOT_LISTED_PROVIDER = 'not_listed_provider',
}

export enum GoRedirectionRouteParameters {
  HELP = 'help',
  SETTINGS = 'settings',
  VEHICLES = 'vehicles',
  INVITE_PARTNERS = 'invite-partners',
  FLEET_MONITOR = 'fleet-monitor',
  TRANSPORTS = 'transports',
  CUSTOMER_SUPPORT_TICKET = 'support-ticket',
  CUSTOMER_FEEDBACK = 'feedback',
  ADD_INTEGRATION = 'add-integration',
  LANDING_PAGE = 'landing-page',
}

export enum ShareRouteParameters {
  // LEGACY entries are kept to prevent breaking users' bookmarks
  STOP = 'stop',
  DELIVERY = 'delivery',
  TOUR = 'tour',
  LEGACY_SHIPMENTS = 'shipments',
  TRANSPORTS = 'transports',
}

export const AnyRouteParameter = {
  companyId: ':company_id',
};

export enum TransportQueryParameters {
  TRANSPORT_SEARCH = 'transport_search',
}

export enum FleetMonitorView {
  MAP = 'map',
  TIMELINE = 'timeline',
}

export enum VehicleManagementContentType {
  MAP = 'map',
  DETAILS = 'details',
}

export enum VehicleManagementSidePanel {
  VEHICLE_DETAILS = 'vehicle_details',
}
