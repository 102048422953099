import { useMutation, useQuery } from '@sixfold/app-data-framework';
import { isNil } from '@sixfold/typed-primitives';
import * as React from 'react';

import {
  ChangeUserProfileMutation,
  ChangeUserProfileMutationVariables,
  UserProfileQuery,
  UserProfileQueryVariables,
  UserRoleInCompany,
} from '../../lib/graphql';
import { changeUserProfile, userProfile } from '../graphql';

export function useUserProfileMutation() {
  const [mutation] = useMutation<ChangeUserProfileMutation, ChangeUserProfileMutationVariables>(changeUserProfile);

  return React.useCallback(
    async (userProfile: { locale: string }) => {
      await mutation({ variables: { input: userProfile } });
    },
    [mutation],
  );
}

export function useUserProfile(companyId?: string):
  | {
      user_id: string;
      first_name: string | null;
      last_name: string | null;
      email: string | null;
      locale: string | null;
      hasBackofficeRights: boolean;
      company?: { role?: UserRoleInCompany };
    }
  | undefined {
  const { data } = useQuery<UserProfileQuery, UserProfileQueryVariables>(userProfile, {
    variables: { companyId: companyId ?? '', skipCompany: companyId === undefined },
    fetchPolicy: 'cache-first',
  });
  const viewer = data?.viewer;

  return React.useMemo(() => {
    if (isNil(viewer)) {
      return undefined;
    }

    return {
      user_id: viewer.user_id,
      first_name: viewer.first_name,
      last_name: viewer.last_name,
      email: viewer.email,
      locale: viewer.locale,
      company: isNil(viewer.company) ? undefined : { role: viewer.company.me?.role ?? undefined },
      hasBackofficeRights: viewer.backoffice_access_enabled ?? false,
    };
  }, [viewer]);
}
